var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('router-link', {
    staticClass: "jr-logo",
    attrs: {
      "to": {
        name: 'home'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/jr-logo.svg'),
      "alt": "WUWOW JR Logo"
    }
  })]), _c('a', {
    staticClass: "contact",
    attrs: {
      "href": "tel:tel:0800885315"
    }
  }, [_vm._v("0800 885 315 幫幫我 上英文 | 撥打專線，立即有專人為您服務")])], 1)]), _c('div', {
    staticClass: "form-upper"
  }, [_c('div', {
    staticClass: "form-visual"
  }, [_c('img', {
    staticClass: "visual-background",
    attrs: {
      "src": require('@/assets/event/trial/bg-form-visual.png'),
      "alt": "表單主視覺背景"
    }
  }), _c('img', {
    staticClass: "spark spark-small",
    attrs: {
      "src": require('@/assets/event/trial/spark-small.png'),
      "alt": "星型裝飾"
    }
  }), _c('img', {
    staticClass: "spark spark-medium",
    attrs: {
      "src": require('@/assets/event/trial/spark-medium.png'),
      "alt": "星型裝飾"
    }
  }), _c('img', {
    staticClass: "spark spark-large",
    attrs: {
      "src": require('@/assets/event/trial/spark-large.png'),
      "alt": "星型裝飾"
    }
  }), _c('button', {
    staticClass: "visual-a",
    attrs: {
      "data-scroll": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openReasonModal('a');
      }
    }
  }, [_c('img', {
    staticClass: "sm",
    attrs: {
      "src": require('@/assets/event/trial/visual-a-sm.png'),
      "alt": "特色a"
    }
  }), _c('img', {
    staticClass: "lg",
    attrs: {
      "src": require('@/assets/event/trial/visual-a-lg.png'),
      "alt": "特色a"
    }
  })]), _c('button', {
    staticClass: "visual-b",
    attrs: {
      "data-scroll": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openReasonModal('b');
      }
    }
  }, [_c('img', {
    staticClass: "sm",
    attrs: {
      "src": require('@/assets/event/trial/visual-b-sm.png'),
      "alt": "特色b"
    }
  }), _c('img', {
    staticClass: "lg",
    attrs: {
      "src": require('@/assets/event/trial/visual-b-lg.png'),
      "alt": "特色b"
    }
  })]), _c('button', {
    staticClass: "visual-c",
    attrs: {
      "data-scroll": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openReasonModal('c');
      }
    }
  }, [_c('img', {
    staticClass: "sm",
    attrs: {
      "src": require('@/assets/event/trial/visual-c-sm.png'),
      "alt": "特色b"
    }
  }), _c('img', {
    staticClass: "lg",
    attrs: {
      "src": require('@/assets/event/trial/visual-c-lg.png'),
      "alt": "特色b"
    }
  })]), _c('button', {
    staticClass: "visual-d",
    attrs: {
      "data-scroll": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openReasonModal('d');
      }
    }
  }, [_c('img', {
    staticClass: "sm",
    attrs: {
      "src": require('@/assets/event/trial/visual-d-sm.png'),
      "alt": "特色b"
    }
  }), _c('img', {
    staticClass: "lg",
    attrs: {
      "src": require('@/assets/event/trial/visual-d-lg.png'),
      "alt": "特色b"
    }
  })])]), _c('div', {
    staticClass: "form-area"
  }, [_c('div', {
    staticClass: "title-area"
  }, [_c('div', {
    staticClass: "main-title"
  }, [_vm._m(0), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/title-abc.svg')
    }
  })]), _vm._m(1)]), _c('div', {
    staticClass: "form-banner",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm.deadlinesAfter ? [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/trial-new-banner.jpg')
    }
  })] : [_c('img', {
    staticClass: "mobile mobile-banner",
    attrs: {
      "src": _vm.mobileBannerSrc
    }
  }), _c('img', {
    staticClass: "tablet tablet-banner",
    attrs: {
      "src": _vm.tabletBannerSrc
    }
  }), _c('img', {
    staticClass: "desktop desktop-banner",
    attrs: {
      "src": _vm.desktopBannerSrc
    }
  })]], 2), _c('div', {
    staticClass: "form-card",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/member-orange.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formUpper.parentName,
      expression: "formUpper.parentName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長姓名（必填）"
    },
    domProps: {
      "value": _vm.formUpper.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formUpper, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/phone-orange.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formUpper.parentPhone,
      expression: "formUpper.parentPhone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長電話（必填）"
    },
    domProps: {
      "value": _vm.formUpper.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formUpper, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/mail-orange.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formUpper.parentEmail,
      expression: "formUpper.parentEmail"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長email（必填）"
    },
    domProps: {
      "value": _vm.formUpper.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formUpper, "parentEmail", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-privacy"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formUpper.privacyPolicyCheck,
      expression: "formUpper.privacyPolicyCheck"
    }],
    attrs: {
      "id": "privacy-upper",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.formUpper.privacyPolicyCheck) ? _vm._i(_vm.formUpper.privacyPolicyCheck, null) > -1 : _vm.formUpper.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formUpper.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formUpper, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formUpper, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formUpper, "privacyPolicyCheck", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "privacy-upper"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/check.svg')
    }
  })]), _c('span', [_vm._v("我同意 WUWOW 線上英文 "), _c('router-link', {
    attrs: {
      "to": {
        path: _vm.privacyUrl
      },
      "target": "_blank"
    }
  }, [_vm._v("隱私權政策")])], 1)]), _c('button', {
    staticClass: "form-btn",
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.formUpper);
      }
    }
  }, [_vm._v("免費搶先預約")]), _c('div', {
    staticClass: "form-divider"
  }, [_c('img', {
    staticStyle: {
      "object-fit": "contain"
    },
    attrs: {
      "src": require('@/assets/event/trial/divider-left.svg')
    }
  }), _c('span', [_vm._v("兒童線上英文")]), _c('img', {
    staticStyle: {
      "object-fit": "contain"
    },
    attrs: {
      "src": require('@/assets/event/trial/divider-right.svg')
    }
  })]), _c('div', {
    staticClass: "gift-area mb-2 mb-xl-4"
  }, [_c('button', {
    staticClass: "gift w-100 ml-0",
    on: {
      "click": function ($event) {
        return _vm.openAbilitiesUpbringingModal();
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v(" 家教課1堂＋英文能力報告")])]), _c('div', {
    staticClass: "gift-area"
  }, [_c('button', {
    staticClass: "gift",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.galleryShow = true;
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v("劍橋兒童英檢單字書")]), _c('button', {
    staticClass: "gift",
    on: {
      "click": function ($event) {
        return _vm.openVideoBook();
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v("宇宙主題 學習單")])])])])]), _c('div', {
    staticClass: "question"
  }, [_c('div', {
    staticClass: "question-container"
  }, [_c('img', {
    staticClass: "question-title mobile",
    attrs: {
      "src": require('@/assets/event/trial/question-title-mobile.png'),
      "alt": "提問標題"
    }
  }), _c('img', {
    staticClass: "question-title tablet",
    attrs: {
      "src": require('@/assets/event/trial/question-title-tablet.png'),
      "alt": "提問標題"
    }
  }), _c('img', {
    staticClass: "question-title desktop",
    attrs: {
      "src": require('@/assets/event/trial/question-title-desktop.png'),
      "alt": "提問標題"
    }
  }), _c('div', {
    staticClass: "saying-container"
  }, [_c('div', {
    staticClass: "question-visual",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/question-visual-mobile.png'),
      "alt": "提問主視覺"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/question-visual-tablet.png'),
      "alt": "提問主視覺"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/question-visual-desktop.png'),
      "alt": "提問主視覺"
    }
  })]), _c('div', {
    staticClass: "saying"
  }, [_c('img', {
    staticClass: "globe",
    attrs: {
      "src": require('@/assets/event/trial/globe.png')
    }
  }), _c('div', {
    staticClass: "famous",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "link",
    attrs: {
      "src": require('@/assets/event/trial/link.svg')
    }
  }), _c('img', {
    staticClass: "avatar",
    attrs: {
      "src": require('@/assets/event/trial/famous-1.png')
    }
  }), _c('div', {
    staticClass: "bubble"
  }, [_c('img', {
    staticClass: "quotation-left",
    attrs: {
      "src": require('@/assets/event/trial/quotation-left.svg')
    }
  }), _c('img', {
    staticClass: "quotation-right",
    attrs: {
      "src": require('@/assets/event/trial/quotation-right.svg')
    }
  }), _c('span', [_vm._v("全球知名語言發展專家—派翠西亞．庫兒博士 (Patricia K. Kuhl, Ph. D.)的研究指出：「0-7歲是人類語言學習的關鍵期，且此時的兒童有能力同時學習兩種語言。」")])])]), _c('div', {
    staticClass: "famous",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "link",
    attrs: {
      "src": require('@/assets/event/trial/link.svg')
    }
  }), _c('img', {
    staticClass: "avatar",
    attrs: {
      "src": require('@/assets/event/trial/famous-2.png')
    }
  }), _c('div', {
    staticClass: "bubble"
  }, [_c('img', {
    staticClass: "quotation-left",
    attrs: {
      "src": require('@/assets/event/trial/quotation-left.svg')
    }
  }), _c('img', {
    staticClass: "quotation-right",
    attrs: {
      "src": require('@/assets/event/trial/quotation-right.svg')
    }
  }), _c('span', [_vm._v("美國神經學家戴蒙（Marian C. Diamond）及霍普森（Janet Hopson）強調：「人類在 12 歲以前腦部的成長發育相當快速，通常能達到成人大腦重量的 90％。」")])])]), _c('div', {
    staticClass: "famous",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "link",
    attrs: {
      "src": require('@/assets/event/trial/link.svg')
    }
  }), _c('img', {
    staticClass: "avatar",
    attrs: {
      "src": require('@/assets/event/trial/famous-3.png')
    }
  }), _c('div', {
    staticClass: "bubble"
  }, [_c('img', {
    staticClass: "quotation-left",
    attrs: {
      "src": require('@/assets/event/trial/quotation-left.svg')
    }
  }), _c('img', {
    staticClass: "quotation-right",
    attrs: {
      "src": require('@/assets/event/trial/quotation-right.svg')
    }
  }), _c('span', [_vm._v("美國深具影響力的認知心理學學術期刊〈Cognition〉刊載：「10 歲前為語言學習關鍵期，此期間加強練習外語，有機會達母語程度！」")])])])])]), _c('div', {
    staticClass: "summary"
  }, [_c('img', {
    staticClass: "quotation-left",
    attrs: {
      "src": require('@/assets/event/trial/quotation-left.svg')
    }
  }), _c('img', {
    staticClass: "quotation-right",
    attrs: {
      "src": require('@/assets/event/trial/quotation-right.svg')
    }
  }), _c('p', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("越早開始學習，越能輕鬆無壓習得英文能力。")]), _vm._m(2)])])]), _c('div', {
    staticClass: "benefit"
  }, [_c('img', {
    staticClass: "mobile benefit-title",
    attrs: {
      "src": require('@/assets/event/trial/benefit-title-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet benefit-title",
    attrs: {
      "src": require('@/assets/event/trial/benefit-title-tablet.png')
    }
  }), _c('img', {
    staticClass: "desktop benefit-title",
    attrs: {
      "src": require('@/assets/event/trial/benefit-title-desktop.png')
    }
  }), _c('div', {
    staticClass: "card-area"
  }, [_c('div', {
    staticClass: "card-1 benefit-card",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-1-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-1-tablet.png')
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-1-desktop.png')
    }
  })]), _c('div', {
    staticClass: "card-2 benefit-card",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-2-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-2-tablet.png')
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-2-desktop.png')
    }
  })]), _c('div', {
    staticClass: "card-3 benefit-card",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-3-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-3-tablet.png')
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/benefit-card-3-desktop.png')
    }
  })])]), _c('img', {
    staticClass: "benefit-abcd",
    attrs: {
      "src": require('@/assets/event/trial/benefit-abcd.svg')
    }
  }), _c('img', {
    staticClass: "bud-left",
    attrs: {
      "src": require('@/assets/event/trial/bud-left.svg')
    }
  }), _c('img', {
    staticClass: "bud-right",
    attrs: {
      "src": require('@/assets/event/trial/bud-right.svg')
    }
  }), _c('img', {
    staticClass: "benefit-curve mobile",
    attrs: {
      "src": require('@/assets/event/trial/benefit-curve-mobile.svg')
    }
  }), _c('img', {
    staticClass: "benefit-curve tablet",
    attrs: {
      "src": require('@/assets/event/trial/benefit-curve-tablet.svg')
    }
  }), _c('img', {
    staticClass: "benefit-curve desktop",
    attrs: {
      "src": require('@/assets/event/trial/benefit-curve-desktop.svg')
    }
  })]), _c('div', {
    staticClass: "learning"
  }, [_c('h2', {
    staticClass: "learning-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("既然英文這麼重要，"), _c('br'), _vm._v("父母該怎麼幫孩子打造 "), _c('br', {
    staticClass: "d-md-none d-block"
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnEnglishTitle.png')
    }
  }), _vm._v(" 呢？")]), _c('div', {
    staticClass: "learning-banner",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "d-xl-block d-none"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/englishTeacher-xl.png')
    }
  })]), _c('div', {
    staticClass: "d-xl-none d-md-block d-none"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/englishTeacher-lg.png')
    }
  })]), _c('div', {
    staticClass: "d-md-none"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/englishTeacher-sm.png')
    }
  })])]), _c('div', {
    staticClass: "learning-main"
  }, [_c('div', {
    staticClass: "d-lg-block d-none"
  }, [_c('img', {
    staticClass: "learning-main_picXl",
    attrs: {
      "src": require('@/assets/event/trial/learnEnglishMain.png')
    }
  })]), _c('div', {
    staticClass: "d-lg-none d-sm-block d-none"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnEnglishMain-tablet.png')
    }
  })]), _c('div', {
    staticClass: "d-sm-none d-flex flex-column justify-content-center align-items-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnEnglishMain-mobile.png')
    }
  })])])]), _c('div', {
    staticClass: "onlinePlatform"
  }, [_c('div', {
    staticClass: "text-center onlinePlatform-title"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/onlinePlatformTitle.png')
    }
  })]), _c('div', {
    staticClass: "text-center onlinePlatform-table",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "d-lg-block d-none text-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/onlinePlatformTable-lg.svg')
    }
  })]), _c('div', {
    staticClass: "d-lg-none text-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/onlinePlatformTable-sm.svg')
    }
  })])])]), _c('div', {
    staticClass: "learnModel"
  }, [_c('div', {
    staticClass: "learnModel-container"
  }, [_c('div', {
    staticClass: "learnModel-wrap d-flex align-items-center justify-content-center flex-xl-nowrap flex-wrap"
  }, [_c('div', {
    staticClass: "learnModel-students",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnModel-Pic.png')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnModel-Pic1.png')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnModel-Pic2.png')
    }
  })]), _c('div', {
    staticClass: "learnModel-main"
  }, [_c('div', {
    staticClass: "ball-small",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-sm-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-sm-desktop.png')
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-sm-desktop.png')
    }
  })]), _c('div', {
    staticClass: "ball-large",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-lg-mobile.png')
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-lg-desktop.png')
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": require('@/assets/event/trial/learnModel-ball-lg-desktop.png')
    }
  })]), _vm._m(3), _vm._m(4), _vm._m(5)])])])]), _c('div', {
    staticClass: "learnReason"
  }, [_c('div', {
    staticClass: "learnReason-container"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-xl learnReason-order",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.scrollForm('.form-lower');
      }
    }
  }, [_vm._v("免費試聽，立即預約")])]), _vm._m(6), _c('ul', {
    staticClass: "learnReason-main"
  }, [_c('li', {
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "learnReason-card"
  }, [_c('div', {
    staticClass: "d-flex align-items-start d-md-block text-md-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason1.png')
    }
  }), _c('div', [_c('h3', {
    staticClass: "learnReason-scondary"
  }, [_vm._v("25 分鐘 1 對 1 家教")]), _c('img', {
    staticClass: "dec",
    attrs: {
      "src": require('@/assets/event/trial/dec.png')
    }
  }), _vm._m(7)])]), _c('span', {
    staticClass: "leaf leaf-M"
  }), _c('span', {
    staticClass: "leaf leaf-L"
  }), _c('span', {
    staticClass: "leaf leaf-S"
  })])]), _c('li', {
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "learnReason-card"
  }, [_c('div', {
    staticClass: "d-flex align-items-start d-md-block text-md-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason2.png')
    }
  }), _c('div', [_c('h3', {
    staticClass: "learnReason-scondary"
  }, [_vm._v("課綱明確 分級嚴謹")]), _c('img', {
    staticClass: "dec",
    attrs: {
      "src": require('@/assets/event/trial/dec.png')
    }
  }), _vm._m(8)])]), _c('span', {
    staticClass: "leaf leaf-M"
  }), _c('span', {
    staticClass: "leaf leaf-L"
  }), _c('span', {
    staticClass: "leaf leaf-S"
  })])]), _c('li', {
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "learnReason-card"
  }, [_c('div', {
    staticClass: "d-flex align-items-start d-md-block text-md-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason3.png')
    }
  }), _c('div', [_c('h3', {
    staticClass: "learnReason-scondary"
  }, [_vm._v("主題沈浸式 互動教學")]), _c('img', {
    staticClass: "dec",
    attrs: {
      "src": require('@/assets/event/trial/dec.png')
    }
  }), _vm._m(9)])]), _c('span', {
    staticClass: "leaf leaf-M"
  }), _c('span', {
    staticClass: "leaf leaf-L"
  }), _c('span', {
    staticClass: "leaf leaf-S"
  })])]), _c('li', {
    attrs: {
      "data-scroll": ""
    }
  }, [_c('div', {
    staticClass: "learnReason-card"
  }, [_c('div', {
    staticClass: "d-flex align-items-start d-md-block text-md-center"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason4.png')
    }
  }), _c('div', [_c('h3', {
    staticClass: "learnReason-scondary"
  }, [_vm._v("嚴選師資 汰弱留強")]), _c('img', {
    staticClass: "dec",
    attrs: {
      "src": require('@/assets/event/trial/dec.png')
    }
  }), _vm._m(10)])]), _c('span', {
    staticClass: "leaf leaf-M"
  }), _c('span', {
    staticClass: "leaf leaf-L"
  }), _c('span', {
    staticClass: "leaf leaf-S"
  })])])])]), _c('div', {
    staticClass: "learnReason-title text-center courseRecord-block"
  }, [_vm._m(11), _c('div', {
    staticClass: "container media-block"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "data-scroll": "",
      "src": require('@/assets/trial/courseRecordVideo.png')
    },
    on: {
      "click": function ($event) {
        return _vm.youtubeModal(_vm.courseRecord);
      }
    }
  })])])]), _c('div', {
    staticClass: "learnReason-materials"
  }, [_vm._m(12), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-xl-6 mb-5 mb-xl-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center dunhuang"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/Dunhuang.svg')
    }
  }), _c('img', {
    staticClass: "mx-4",
    attrs: {
      "src": require('@/assets/material_2023/close.svg')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/material_2023/Caves.svg')
    }
  })]), _c('p', {
    staticClass: "p-1",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("與深耕70年外語教育的敦煌教育集團合作，選用 CAVES KIDS 教材，主題內容多元豐富，涵蓋歷史、科技、藝術...，課後提供仿照劍橋兒童英檢題型及練習音檔，按部就班紮實學英文，同時跨領域培養孩子們 21世紀所需的知識能力與素養。")])]), _c('div', {
    staticClass: "col-12 col-xl-6"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer dunhuang-img",
    attrs: {
      "data-scroll": "",
      "src": require('@/assets/material_2023/video.svg')
    },
    on: {
      "click": function ($event) {
        return _vm.youtubeModal(_vm.materialVideo);
      }
    }
  })])])]), _c('div', {
    staticClass: "bookcaseWrap"
  }, [_c('div', {
    staticClass: "bookcase-first",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('h3'), _c('ul', {
    on: {
      "click": function ($event) {
        return _vm.openMaterialModal('modalContentTwo');
      }
    }
  }, [_c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book1.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book2.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book3.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book4.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book5.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book6.jpg')
    }
  })])])]), _c('div', {
    staticClass: "bookcase-two",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('h3'), _c('ul', {
    on: {
      "click": function ($event) {
        return _vm.openMaterialModal('modalContentOne');
      }
    }
  }, [_c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book2-1.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book2-2.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book2-3.jpg')
    }
  })]), _c('li', {
    staticClass: "cursor-pointer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/book2-4.jpg')
    }
  })])])])])]), _c('div', {
    staticClass: "learnReason-bannerContainer"
  }, [_c('div', {
    staticClass: "learnReason-banner"
  }, [_vm._m(13), _c('div', {
    staticClass: "learnReason-arrows",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-arrow.png')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-arrow.png')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-arrow.png')
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-arrow.png')
    }
  })]), _vm._m(14), _c('input', {
    staticClass: "btn-check d-none",
    attrs: {
      "type": "checkbox",
      "id": "btn-check",
      "autocomplete": "off"
    }
  }), _c('div', {
    staticClass: "learnReason-table"
  }, [_c('div', {
    staticClass: "learnReason-tableWrap"
  }, [_c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-tableXl.png')
    }
  })]), _c('div', {
    staticClass: "d-xl-none"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/learnReason-tableSm.png')
    }
  })])])])])])]), _c('grade-section'), _c('div', {
    staticClass: "recommend"
  }, [_c('div', {
    staticClass: "recommend-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/title-leaft.png')
    }
  }), _c('span', [_vm._v("快樂學習 好評推薦")]), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/title-leaft.png')
    }
  })]), _vm._m(15), _c('div', {
    staticClass: "home-container",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('carousel', {
    staticClass: "p-2",
    attrs: {
      "data-scroll": "",
      "perPage": 1,
      "perPageCustom": [[768, 3], [992, 4]],
      "autoplay": true,
      "autoplayTimeout": 5000,
      "speed": 1000,
      "loop": true,
      "autoplayHoverPause": true,
      "navigationEnabled": true
    }
  }, _vm._l(_vm.cardList, function (card, index) {
    return _c('slide', {
      staticClass: "justify-content-center"
    }, [_c('div', {
      staticClass: "slider-card"
    }, [_c('div', {
      staticClass: "thumb text-center"
    }, [_c('span', {
      staticClass: "tag"
    }, [_vm._v(_vm._s(card.tag))]), _c('img', {
      attrs: {
        "src": card.thumb,
        "alt": `${card.topic}的預覽圖片`
      }
    }), card.video ? _c('button', {
      staticClass: "play",
      on: {
        "click": function ($event) {
          return _vm.youtubeModal(card.embed);
        }
      }
    }, [_c('svg', {
      attrs: {
        "width": "40",
        "height": "40",
        "viewbox": "0 0 40 40",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('circle', {
      attrs: {
        "cx": "20",
        "cy": "20",
        "r": "20",
        "fill": "#82C1EA"
      }
    }), _c('path', {
      attrs: {
        "d": "M28.6638 19.4617C29.3305 19.8466 29.3305 20.8089 28.6638 21.1938L16.9097 27.98C16.2431 28.3649 15.4097 27.8838 15.4097 27.114L15.4097 13.5415C15.4097 12.7717 16.2431 12.2906 16.9097 12.6755L28.6638 19.4617Z",
        "fill": "white"
      }
    })])]) : _vm._e()]), _c('div', {
      staticClass: "card-body"
    }, [_c('div', {
      staticClass: "info text-center"
    }, [_c('p', {
      staticClass: "topic"
    }, [_vm._v(_vm._s(card.topic))]), _c('div', {
      staticClass: "detail"
    }, [_c('P', [_vm._v(_vm._s(card.name))]), card.age ? _c('p', [_vm._v(" " + _vm._s(card.age) + "歲")]) : _vm._e()], 1)]), _c('div', {
      staticClass: "card-title d-flex justify-content-center align-items-center"
    }, [_c('h5', [_vm._v(_vm._s(card.title))])]), _c('p', {
      staticClass: "content"
    }, [_vm._v(_vm._s(card.content))]), _c('div', {
      staticClass: "control d-flex"
    }, [_c('a', {
      staticClass: "link d-flex ml-auto align-items-center",
      attrs: {
        "href": card.link,
        "target": "_blank"
      }
    }, [_c('span', [_vm._v("觀看更多"), _c('svg', {
      attrs: {
        "width": "20",
        "height": "20",
        "viewBox": "0 0 20 20",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('circle', {
      attrs: {
        "cx": "10",
        "cy": "10",
        "r": "9.3",
        "fill": "white",
        "stroke": "#C7C7C7",
        "stroke-width": "1.4"
      }
    }), _c('path', {
      attrs: {
        "d": "M4.87818 9.58436C4.98848 9.47405 5.13809 9.41208 5.29408 9.41208H12.1086L9.58301 6.88766C9.47257 6.77722 9.41052 6.62743 9.41052 6.47124C9.41052 6.31505 9.47257 6.16526 9.58301 6.05481C9.69346 5.94437 9.84325 5.88232 9.99944 5.88232C10.1556 5.88232 10.3054 5.94437 10.4159 6.05481L13.9449 9.58383C13.9997 9.63847 14.0431 9.70337 14.0728 9.77483C14.1024 9.84628 14.1177 9.92289 14.1177 10.0003C14.1177 10.0776 14.1024 10.1542 14.0728 10.2257C14.0431 10.2971 13.9997 10.362 13.9449 10.4167L10.4159 13.9457C10.3612 14.0004 10.2963 14.0438 10.2248 14.0734C10.1534 14.103 10.0768 14.1182 9.99944 14.1182C9.9221 14.1182 9.84552 14.103 9.77407 14.0734C9.70262 14.0438 9.6377 14.0004 9.58301 13.9457C9.52833 13.891 9.48495 13.8261 9.45535 13.7546C9.42576 13.6832 9.41052 13.6066 9.41052 13.5293C9.41052 13.4519 9.42576 13.3754 9.45535 13.3039C9.48495 13.2325 9.52833 13.1675 9.58301 13.1128L12.1086 10.5884H5.29408C5.13809 10.5884 4.98848 10.5265 4.87818 10.4162C4.76788 10.3059 4.70591 10.1562 4.70591 10.0003C4.70591 9.84426 4.76788 9.69466 4.87818 9.58436Z",
        "fill": "#C7C7C7"
      }
    })])])])])])])]);
  }), 1)], 1)]), _c('div', {
    staticClass: "form-lower"
  }, [_c('div', {
    staticClass: "form-area"
  }, [_c('img', {
    staticClass: "form-title mobile",
    attrs: {
      "src": require('@/assets/event/trial/form-lower-title-mobile.png')
    }
  }), _c('img', {
    staticClass: "form-title tablet",
    attrs: {
      "src": require('@/assets/event/trial/form-lower-title-tablet.png')
    }
  }), _c('img', {
    staticClass: "form-title desktop",
    attrs: {
      "src": require('@/assets/event/trial/form-lower-title-desktop.png')
    }
  }), _c('div', {
    staticClass: "form-card"
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/member-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.parentName,
      expression: "formLower.parentName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長姓名（必填）"
    },
    domProps: {
      "value": _vm.formLower.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/phone-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.parentPhone,
      expression: "formLower.parentPhone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長電話（必填）"
    },
    domProps: {
      "value": _vm.formLower.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/mail-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.parentEmail,
      expression: "formLower.parentEmail"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長email（必填）"
    },
    domProps: {
      "value": _vm.formLower.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "parentEmail", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/kid-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.studentChineseName,
      expression: "formLower.studentChineseName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "孩子中文姓名（非必填）"
    },
    domProps: {
      "value": _vm.formLower.studentChineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "studentChineseName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/kid-a-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.studentEnglishName,
      expression: "formLower.studentEnglishName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "小孩英文名稱（非必填）"
    },
    domProps: {
      "value": _vm.formLower.studentEnglishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "studentEnglishName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/kid-five-blue.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.studentAge,
      expression: "formLower.studentAge"
    }],
    attrs: {
      "type": "text",
      "placeholder": "孩子年齡（非必填）"
    },
    domProps: {
      "value": _vm.formLower.studentAge
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formLower, "studentAge", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-privacy"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formLower.privacyPolicyCheck,
      expression: "formLower.privacyPolicyCheck"
    }],
    attrs: {
      "id": "privacy-lower",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.formLower.privacyPolicyCheck) ? _vm._i(_vm.formLower.privacyPolicyCheck, null) > -1 : _vm.formLower.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formLower.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formLower, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formLower, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formLower, "privacyPolicyCheck", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "privacy-lower"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/check.svg')
    }
  })]), _c('span', [_vm._v("我同意 WUWOW 線上英文 "), _c('router-link', {
    attrs: {
      "to": {
        path: _vm.privacyUrl
      },
      "target": "_blank"
    }
  }, [_vm._v("隱私權政策")])], 1)]), _c('div', {
    staticClass: "form-divider"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/divider-left.svg')
    }
  }), _c('span', [_vm._v("兒童線上英文")]), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/divider-right.svg')
    }
  })]), _c('div', {
    staticClass: "gift-area mb-2 mb-xl-4"
  }, [_c('button', {
    staticClass: "gift w-100 ml-0",
    on: {
      "click": function ($event) {
        return _vm.openAbilitiesUpbringingModal();
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v(" 家教課1堂＋英文能力報告")])]), _c('div', {
    staticClass: "gift-area"
  }, [_c('button', {
    staticClass: "gift",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.galleryShow = true;
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v("劍橋兒童英檢單字書")]), _c('button', {
    staticClass: "gift",
    on: {
      "click": function ($event) {
        return _vm.openVideoBook();
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('img', {
    attrs: {
      "src": require('@/assets/event/trial/gift.svg')
    }
  }), _vm._v("宇宙主題 學習單")])]), _c('button', {
    staticClass: "form-btn",
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.formLower);
      }
    }
  }, [_vm._v("免費搶先預約")])])])]), _c('div', {
    staticClass: "lioshutan-footer bg-lioshutan py-4"
  }, [_c('div', {
    staticClass: "d-block d-md-none py-4"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "d-flex mt-4 justify-content-center"
  }, [_c('a', {
    staticClass: "mr-1 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/icon-fb.svg')
    }
  }), _c('p', [_vm._v("wuwow jr")])])])]), _vm._m(16), _vm._m(17)]), _c('div', {
    staticClass: "d-none d-md-block d-lg-none"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row py-4"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "d-flex mt-4 justify-content-center"
  }, [_c('a', {
    staticClass: "mr-1 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/icon-fb.svg')
    }
  }), _c('p', [_vm._v("wuwow jr")])])])])]), _vm._m(18)])])]), _c('div', {
    staticClass: "d-none d-md-none d-lg-block container"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-around py-5"
  }, [_c('div', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "d-flex mt-4 justify-content-left"
  }, [_c('a', {
    staticClass: "mr-1 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/icon-fb.svg')
    }
  }), _c('p', [_vm._v("wuwow jr")])])])]), _vm._m(19), _vm._m(20)])])]), _c('button', {
    staticClass: "scrollForm",
    on: {
      "click": function ($event) {
        return _vm.scrollTop();
      }
    }
  }, [_c('img', {
    staticClass: "init",
    attrs: {
      "src": require('@/assets/event/trial/scrollForm.png')
    }
  }), _c('img', {
    staticClass: "hover",
    attrs: {
      "src": require('@/assets/event/trial/scrollForm-hover.png')
    }
  })]), _c('transition', [_vm.doc.isOpen ? _c('div', {
    staticClass: "docModal"
  }, [_c('span', {
    staticClass: "doc-close",
    on: {
      "click": function ($event) {
        return _vm.closeAbilitiesUpbringingModal();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewbox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
      "fill": "white"
    }
  })])]), _vm.doc.componentText === 'content' ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "order-sm-1 order-2"
  }, [_c('h3', {
    staticClass: "doc-title"
  }, [_vm._v(_vm._s(_vm.doc.content.title))]), _c('h3', {
    staticClass: "doc-subtitle"
  }, [_vm._v(_vm._s(_vm.doc.content.subTitle))]), _c('div', {
    staticClass: "doc-content"
  }, [_c('img', {
    attrs: {
      "src": _vm.doc.content.imgPath,
      "alt": ""
    }
  })])]), _vm.doc.contentTwo.open ? _c('div', {
    staticClass: "order-sm-2 order-1"
  }, [_c('h3', {
    staticClass: "doc-title"
  }, [_vm._v(_vm._s(_vm.doc.contentTwo.title))]), _c('h3', {
    staticClass: "doc-subtitle"
  }, [_vm._v(_vm._s(_vm.doc.contentTwo.subTitle))]), _c('div', {
    staticClass: "doc-content"
  }, [_c('img', {
    attrs: {
      "src": _vm.doc.contentTwo.imgPath,
      "alt": ""
    }
  })])]) : _vm._e()]) : _vm._e(), _vm.doc.componentText === 'video' ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "order-sm-1 order-2"
  }, [_c('h3', {
    staticClass: "doc-title mb-5"
  }, [_vm._v("宇宙主題 學習單")]), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('img', {
    staticClass: "w-100",
    attrs: {
      "src": require('@/assets/event/trial/universe-sheets.png')
    }
  })])])]) : _vm._e()]) : _vm._e()]), _c('transition', [_vm.reason.isOpen ? _c('div', {
    staticClass: "reasonModal",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.reason.isOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "reason-content",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.reason.isOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "reason-dialog"
  }, [_c('span', {
    staticClass: "reason-close",
    on: {
      "click": function ($event) {
        _vm.reason.isOpen = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewbox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
      "fill": "white"
    }
  })])]), _c('div', {
    staticClass: "d-flex align-items-start d-md-block text-md-center"
  }, [_c('img', {
    attrs: {
      "src": _vm.reason.content.imgPath
    }
  }), _c('div', [_c('h3', {
    staticClass: "learnReason-scondary"
  }, [_vm._v(_vm._s(_vm.reason.content.title))]), _c('img', {
    staticClass: "dec",
    attrs: {
      "src": require('@/assets/event/trial/dec.png')
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.reason.content.pHTML)
    }
  })])]), _c('span', {
    staticClass: "leaf leaf-M"
  }), _c('span', {
    staticClass: "leaf leaf-L"
  }), _c('span', {
    staticClass: "leaf leaf-S"
  })])])]) : _vm._e()]), _c('transition', [_vm.material ? _c('div', {
    staticClass: "reasonModal",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.material = false;
      }
    }
  }, [_c('div', {
    staticClass: "material-content"
  }, [_c('div', {
    staticClass: "material-dialog"
  }, [_c('span', {
    staticClass: "reason-close",
    on: {
      "click": function ($event) {
        _vm.material = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewbox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
      "fill": "white"
    }
  })])]), _vm.materialContent === 'modalContentOne' ? [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/modalContent-1.png')
    }
  })] : [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/modalContent-2.svg')
    }
  })]], 2)])]) : _vm._e()]), _c('youtube-modal', {
    attrs: {
      "src": _vm.youtubeUrl
    }
  }), _c('gallery-modal', {
    attrs: {
      "gallery-show": _vm.galleryShow
    },
    on: {
      "update:galleryShow": function ($event) {
        _vm.galleryShow = $event;
      },
      "update:gallery-show": function ($event) {
        _vm.galleryShow = $event;
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v(" 孩子愛上"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("學英語")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('span', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v(" 學的不只是語言，")]), _c('span', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("更是未來"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v(" 無限可能")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("讓英文不再是第二外語，"), _c('br', {
    staticClass: "mobile"
  }), _vm._v("而是寶貝們的「"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("母語")]), _vm._v("」！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "learnModel-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('span', {
    staticClass: "text-major"
  }, [_vm._v("線上學習")]), _vm._v("模式，已成主流！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "learnModel-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("後疫情時代"), _c('span', {
    staticClass: "text-major"
  }, [_vm._v("教育部")]), _vm._v("也這麼做！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-center text-xl-left",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("以「班班有網路，生生用平板」為口號，"), _c('br', {
    staticClass: "mobile"
  }), _vm._v("政府提升學校網路、提供載具，"), _c('br', {
    staticClass: "mobile"
  }), _vm._v("並充實數位內容，線上英文學習已然成為趨勢。")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "learnReason-title text-center"
  }, [_c('h2', {
    staticClass: "mb-5"
  }, [_vm._v("首選"), _c('span', {
    staticClass: "mx-1 learnReason-scondary"
  }, [_vm._v("WUWOW Jr")]), _c('br', {
    staticClass: "d-xl-none"
  }), _vm._v("兒童線上英文的理由")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("短時間專注學習，"), _c('br'), _vm._v("家教型VIP精緻學"), _c('br'), _vm._v("寶貝進步超有感！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("以明確課綱對應國際標準分級，"), _c('br'), _vm._v("漸進提升孩子的英文，"), _c('br'), _vm._v("與國際接軌。")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("以日常情境激發學習熱情，"), _c('br'), _vm._v("在互動中同步練習"), _c('br'), _vm._v("聽、說、讀、寫，"), _c('br'), _vm._v("全方位沈浸式英文學習。")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("師資皆有英文教學認證"), _c('br'), _vm._v("及兒美教學經驗，"), _c('br'), _vm._v("輔以定期回訓汰留，"), _c('br'), _vm._v("維持最佳教學品質。")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("一對一"), _c('span', {
    staticStyle: {
      "color": "#75CDEC"
    }
  }, [_vm._v("教學課程實錄")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "learnReason-materials-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("課程教材"), _c('span', [_vm._v("Textbook")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "mb-4"
  }, [_vm._v("想知道更多父母選擇"), _c('br', {
    staticClass: "d-block d-md-none"
  }), _c('span', {
    staticClass: "mx-1 learnReason-scondary"
  }, [_vm._v(" WUWOW Jr")]), _vm._v("的理由？")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('label', {
    staticClass: "btn btn-xl learnReason-more",
    attrs: {
      "for": "btn-check"
    }
  }, [_vm._v("點我看更多")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "recommend-subtitle"
  }, [_vm._v(" 所有 WUWOW Jr 的孩子們，"), _c('br', {
    staticClass: "mobile"
  }), _vm._v("每天最期待的事—展開英文學習！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light py-5",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-50 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-50 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-sm-6"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light mb-3",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]), _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);

}]

export { render, staticRenderFns }